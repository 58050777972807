import React, { useState, useEffect } from 'react';
import { InventoryPerMkt } from '../../../server/InventoryUpdate'
import { useSelector } from 'react-redux'

const ProductLocationPopUp = ({ product }) => {

    const { stationId, selectedProduct, items,stockType } = useSelector(state => ({
		stationId: state.user.stationId,
		selectedProduct: state.selectedProduct.product,
		items: state.displayProduct.itemsShow,
		stockType: state.parameters.stock_type

	}))
	const [location, setLocation] = useState([]);
	const [hasReturn, setHasReturn] = useState(false);
	const [itemsShow, setItemsShow] = useState(items)
	if (!product && selectedProduct) {
		product = selectedProduct;
	
		const matchingProduct = itemsShow.find(item => item.Mkt === selectedProduct.Mkt);
		if (matchingProduct) {
			product = matchingProduct;
		}
	}
	
	useEffect(() => {
		InventoryPerMkt(product.Mkt, stationId)
			.then(x => {
				const data = x.data.filter(x => x.StokeTypeId === 1);
				setLocation(data)
				setHasReturn(true);
			})
			.catch(() => console.log("error"));
	}, [])

	return (
		<div className="product-location">
			<div className='product-header'>
				<h3>שם מוצר: {product.ProductFullDescription}</h3>
				<h3>מק״ט: {product.Mkt}</h3>
				<h3>ברקוד: {product.Barcode}</h3>
				<h3>כמות בפועל: {product.Quantity}</h3>
				<h3>כמות זמינה להזמנות: {product.QuantityCanOrder}</h3>
			</div>
			<div className='table-location'>
				<table>
					<tbody>
						<tr>
							<th>מיקום</th>
							<th>כמות</th>
							<th>כמות זמין</th>
							<th>תוקף</th>
							<th>מצב מלאי</th>
						</tr>
						{location.length ? location.map(x => <tr key={x.Id}>
							<td>{x.FullLocationName}</td>
							<td>{x.Quantity}</td>
							<td>{x.QuantityCanCollect}</td>
							<td>{x.Expired ? new Date(x.Expired).toLocaleDateString('en-GB') : null}</td>
							<td>{stockType.find(type => type.Id === x.StokeTypeId)?.Name || "לא נמצא"}</td>
						</tr>
						) : hasReturn ? <tr><td >לא נמצא מיקומים במלאי</td></tr> : null}
					</tbody>
				</table >
			</div>
		</div>
	)
}

export default ProductLocationPopUp

