import React, { useEffect, useState } from 'react'
import { setUserEntry } from '../../server/Customer'
import { useDispatch } from 'react-redux';
import UserSighIn from './UserSighin';
import UserLogin from './UserLogin';
import PopUp from '../containers/PopUp';
import NewAddress from '../addressShipping/NewAddress';
import { allPolygon } from '../../server/Polygon'
import { globalFileIcon } from '../../../axiosInstance';

const UserEntry = ({typeOfUser}) => {
	const dispatch = useDispatch();
	 const [isSignUpPopupOpen, setSignUpPopupOpen] = useState(false);
	 const [polygonState, setPolygonState] = useState([]);

	 const closeSignUpPopup = () => setSignUpPopupOpen(false);

	useEffect(() => {
		const fetchPolygon = async () => {
			try {
				const response = await allPolygon();
				const polygons = response.data.map(item => item.polygon).flat();
				setPolygonState(polygons);
			} catch (error) {
				console.error("Error occurred:", error);
			}
		};
	
		fetchPolygon();
	}, []);

	return (
		<>
		{!isSignUpPopupOpen && (
			<PopUp width='700px' close={() => dispatch(setUserEntry(false))}>
				<div className="user-entry">
					<div className='user-entry-header'>
					<h3>כמה פרטים קצרים ויוצאים לדרך</h3>
					<div className='tab-header'>
						<button onClick={() => dispatch(setUserEntry([true, "login"]))} className={typeOfUser === "login" ?'tab login':'tab'}>כניסה לחשבון</button>
						<button onClick={() => {
							dispatch(setUserEntry([true, "signIn"]));
							setSignUpPopupOpen(true); 
						}}
						className={typeOfUser === "signIn" ?'tab signIn':'tab'}>יצירת חשבון חדש</button>
					</div>
					</div>
					{typeOfUser === "login" ?
						<UserLogin  />
						: <UserSighIn parentName='signIn' />}

				</div>
			</PopUp>
		)}
        {isSignUpPopupOpen && (
			<PopUp width='570px' close={closeSignUpPopup}>
				<div className="new-signup-popup">
					<h2>ברוך הבא ליצירת חשבון חדש!</h2>
					<h3>רגע לפני שממשיכים,	בא נבדוק שאנו באמת מגיעים אליכם...</h3>
					<NewAddress polygonState={polygonState} onlyStreet={true} closeSignUpPopup={closeSignUpPopup}></NewAddress>	
					<div className='site-icon-address'><img  loading="lazy" src={globalFileIcon + 'site-icon.svg'} alt="site" /></div>				
				</div>
			</PopUp>
        )}
        </>

	)
}

export default UserEntry