import React, { useState, Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { globalFileServer, globalFileIcon } from '../../../../axiosInstance';
import PopUp from "../../containers/PopUp";
import ProductPage from "./ProductPopUp";
import { GetPriceList } from '../../../server/PriceList';

const ProductPageWithImg = ({ close, selectedProd }) => {
    const [imageModal, setImageModal] = useState(false);
    const [company, setCompany] = useState([]);

    useEffect(() => {
        getCompareData();
    }, []);

    const getCompareData = () => {
        GetPriceList(selectedProd.Mkt, selectedProd.Barcode, 1)
            .then(x => {
                setCompany(x.data.list);
            })
            .catch(() => console.log("error"));
    };
 
    return (
        <Fragment>
            <PopUp width='800px' close={close}>
                <ProductPage selectedProd={selectedProd} setImageModal={(data) => setImageModal(data)} />
                <div className="price-compare-table">
                    <h1 className="table-title">השוואת מחירים בחנויות אחרות</h1>
                    <div className="table-container">
                        <table>
                            <tbody>
                                {company.map((store, index) => {
                                    const previousRowColor = index % 2 === 0 ? "#e0e0e0" : "white"; 
                                    return (
                                        <Fragment key={index}>
                                            <tr style={{ backgroundColor: previousRowColor }}>
                                                <td>{store["שם החברה"]}</td>
                                                <td>{store["כתובת"]}</td>
                                                <td>{store["מחיר מכירה"]} ₪</td>
                                            </tr>
                                            {store["תאור מבצע"] && (
                                                <tr className="sale-row" style={{ backgroundColor: previousRowColor }}>
                                                    <td colSpan={3}>
                                                        {store["תאור מבצע"]} עד {new Date(store["תאריך שינוי"]).toLocaleDateString('he-IL')}
                                                    </td>
                                                </tr>
                                            )}
                                        </Fragment>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>

                
                <div onClick={close} className="overflow"></div>
            </PopUp>
            {imageModal ?
                <div className='img-popup'>
                    <div className="close" onClick={() => setImageModal(false)}>
                        <img  loading="lazy" src={globalFileIcon + 'close-menu.svg'} alt="close"    />
                    </div>
                    <img  loading="lazy" className='product-img' src={globalFileServer + "products/" + imageModal}   alt={selectedProd.ProductName} />
                </div>
                : null}


        </Fragment>
    )
}

export default ProductPageWithImg
